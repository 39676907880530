import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Identity } from "@/types/Identity";
import { ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { GridSortDirection } from "../../GridTable";
import { GridCol } from "../../types/GridCol";
import { Sort, SortAsc, SortContainer, SortDesc } from "../GridItem.styles";

type Props<T extends Identity, TAdditional> = {
  sortedColumn?: GridCol<T, TAdditional>;
  sortDirection?: GridSortDirection | null;
  column: GridCol<T, TAdditional>;
  sortBy?: ReactNode;
  disableSorting?: boolean;
};

export const GridSortContainer = <T extends Identity, TAdditional>({
  sortedColumn,
  sortDirection,
  column,
  sortBy,
  disableSorting,
}: Props<T, TAdditional>) => {
  const intl = useIntl();
  const getMessage = useCallback(
    (direction: string) =>
      intl.$t({ id: "SORT_BY_VAR" }, { SortDirection: direction, sortBy }),
    [intl, sortBy],
  );
  const className = useMemo(
    () => (disableSorting ? "text-gray-500" : ""),
    [disableSorting],
  );
  return (
    <SortContainer>
      {sortedColumn === column || sortedColumn?.sortKey === column.sortKey ? (
        sortDirection === GridSortDirection.Ascending ? (
          <Tooltip id="sort" element={<SortAsc className={className} />}>
            {getMessage(intl.$t({ id: "SORT_ASC" }))}
          </Tooltip>
        ) : sortDirection === GridSortDirection.Descending ? (
          <Tooltip id="sort" element={<SortDesc className={className} />}>
            {getMessage(intl.$t({ id: "SORT_DESC" }))}
          </Tooltip>
        ) : (
          <Tooltip id="sort" element={<Sort className={className} />}>
            {getMessage(intl.$t({ id: "SORT_ASC" }))}
          </Tooltip>
        )
      ) : (
        <Tooltip id="sort" element={<Sort className={className} />}>
          {getMessage(intl.$t({ id: "SORT_ASC" }))}
        </Tooltip>
      )}
    </SortContainer>
  );
};
