import { PDF_FONT } from "@/common/const";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { FontStyle } from "jspdf-autotable";
import { IntlShape } from "react-intl";
import { formattedDate } from "../../dates/DateView";
import { getUserName } from "../../users/getUserName";
import { drawLogo, LOGO_SIZE } from "../drawLogo";

const HEADER_HEIGHT = 7;
const FAKE_COLUMN_WIDTH = 3;
const CELL_PADDING = 3;
const LEFT_COLUMN_WIDTH = 27;

const addProjectCommonInfo = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
      cellPadding: { vertical: 0.5, horizontal: 0 },
    },
    columnStyles: {
      0: { cellWidth: LEFT_COLUMN_WIDTH },
    },
    body: [
      [
        {
          content: `${intl.$t({ id: "PO_DATE" })}:`,
          styles: { fontStyle: "bold" },
        },
        formattedDate({
          date: new Date(release?.createdAt || 0),
        }),
      ],
      ...(release.buyout
        ? [
            [
              {
                content: `${intl.$t({ id: "BUYOUT_NUMBER_SYMBOL" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              release.buyout.clientIdentifier,
            ],
          ]
        : []),
      ...(release.sequenceNumber
        ? [
            [
              {
                content: `${intl.$t({ id: "DELIVERY_WITH_NUMBER" }, { number: "" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              release.sequenceNumber,
            ],
          ]
        : []),
      [
        {
          content: `${intl.$t({ id: "ORDER_TYPE" }, { number: "" })}:`,
          styles: { fontStyle: "bold" as FontStyle },
        },
        release.type.name,
      ],
      [
        {
          content: `${intl.$t({ id: "PROJECT" })}:`,
          styles: { fontStyle: "bold" },
        },
        `${release?.project?.name}${release?.project?.jobNumber ? ` (${release.project.jobNumber})` : ""}`,
      ],
    ],
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (doc as any).lastAutoTable.finalY - y;
};

const addTermsInfo = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
      cellPadding: { vertical: 0.5, horizontal: 0 },
    },
    columnStyles: {
      0: { cellWidth: LEFT_COLUMN_WIDTH },
    },
    body: [
      [
        {
          content: `${intl.$t({ id: "DELIVERY_DATE" })}:`,
          styles: { fontStyle: "bold" as FontStyle },
        },
        release.time
          ? formattedDate({
              date: new Date(release.time),
            })
          : "",
      ],

      [
        {
          content: `${intl.$t({ id: "REQUESTED_BY" })}:`,
          styles: { fontStyle: "bold" as FontStyle },
        },
        getUserName(release.createdBy),
      ],
      [
        {
          content: `${intl.$t({ id: "PURCHASING_AGENT" })}:`,
          styles: { fontStyle: "bold" as FontStyle },
        },
        release.submittedBy ? getUserName(release.submittedBy) : "",
      ],
      [
        {
          content: `${intl.$t({ id: "PAYMENT_TERMS" })}:`,
          styles: { fontStyle: "bold" as FontStyle },
        },
        release.paymentTerm
          ? `${intl.$t({ id: "NET" })} ${release.paymentTerm}`
          : "",
      ],
    ],
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (doc as any).lastAutoTable.finalY - y;
};

export const releaseHeaderUpperPart = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
  contractorLogo: string,
  contractorLogoProportion: number,
) => {
  let topOffset = topSpacing;
  autoTable(doc, {
    startY: topSpacing,
    theme: "plain",
    styles: {
      font: PDF_FONT,
    },
    head: [["", "", "", "", ""]],
    body: [["", "", "", "", ""]],
    columnStyles: {
      1: { cellWidth: FAKE_COLUMN_WIDTH },
      3: { cellWidth: FAKE_COLUMN_WIDTH },
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    didDrawCell: function (data) {
      if (data.row.section === "head") {
        if (data.column.index === 2) {
          doc.text(
            `${intl.$t({ id: release.poNumber ? "PO_HASH" : "DELIVERY_WITH_NUMBER" }, { number: "" })}:`,
            data.cell.x + CELL_PADDING,
            data.cell.y + 4.8,
          );
          doc.text(
            `${release.poNumber ?? release.sequenceNumber ?? ""}`,
            data.cell.x + CELL_PADDING + LEFT_COLUMN_WIDTH,
            data.cell.y + 4.8,
          );
        }
      } else if (data.row.section === "body") {
        switch (data.column.index) {
          case 0: {
            if (contractorLogo) {
              drawLogo(
                doc,
                contractorLogo,
                contractorLogoProportion,
                data.cell.x,
                topSpacing,
              );
              topOffset += LOGO_SIZE;
            }
            break;
          }
          case 2: {
            const y = addProjectCommonInfo(
              doc,
              release,
              intl,
              data.cell.x + CELL_PADDING,
              topSpacing + HEADER_HEIGHT + CELL_PADDING,
              data.cell.width - 2 * CELL_PADDING,
            );
            topOffset = Math.max(topOffset, topSpacing + HEADER_HEIGHT + y);
            break;
          }
          case 4: {
            const y = addTermsInfo(
              doc,
              release,
              intl,
              data.cell.x + CELL_PADDING,
              topSpacing + HEADER_HEIGHT + CELL_PADDING,
              data.cell.width - 2 * CELL_PADDING,
            );
            topOffset = Math.max(topOffset, topSpacing + HEADER_HEIGHT + y);
            break;
          }
          default:
            break;
        }
      }
    },
    willDrawCell: (data) => {
      if (data.row.section === "head") {
        data.cell.height = HEADER_HEIGHT;
        if (data.column.index < 2) {
          doc.setFillColor(255, 255, 255);
        }
      }
    },
  });
  return topOffset;
};
