import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  Base,
  LogoContainer,
  MdContainer,
  MdFixedContainer,
  SmContainer,
  SmFixedContainer,
  XsFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  QueryReleasesSortBy,
  ReleasePartialFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { getVendorContacts } from "../../../../../../../common/utils/getVendorContacts";
import { DeliveryActionButton } from "./DeliveryActionButton";
import { DeliveryAllItemsCheckbox } from "./DeliveryAllItemsCheckbox";
import { DeliveryCheckbox } from "./DeliveryCheckbox";
import { DeliveryIssues } from "./DeliveryIssues";
import { ReleaseActions } from "./ReleaseActions";
import { ReleaseAmount } from "./ReleaseAmount";
import { ReleaseCreateDate } from "./ReleaseCreateDate";
import { ReleaseMissingCostCode } from "./ReleaseMissingCostCode";
import { ReleaseName } from "./ReleaseName";
import { ReleaseStatusChipWithApprovals } from "./ReleaseStatusChipWithApprovals";
import { ReleaseTimeAndType } from "./ReleaseTimeAndType";
const PoHashWrapper = tw(SmContainer)``;
const ReleaseWrapper = tw(Base)`col-span-2 basis-32`;
const ProjectWrapper = tw(MdContainer)`col-span-2`;
const StatusContainer = tw(Base)`w-25 basis-25`;
const PoNumber = tw.div`text-xs`;
const WarningsContainer = tw(Base)`w-5 basis-5`;
const ActionsContainer = tw(Base)`w-38 basis-38`;

export const useDeliveriesConfiguration = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const configuration: Array<GridCol<ReleasePartialFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XsFixedContainer,
        position: "center",
        item: ({ item }) => <DeliveryCheckbox delivery={item} />,
        header: <DeliveryAllItemsCheckbox />,
      },
      {
        wrapper: LogoContainer,
        header: <FormattedMessage id="VENDOR" />,
        item: ({ item, index }) => (
          <VendorLogo
            logoImageUrl={item.sellerOrgLocation?.org.photoUrl}
            address={item.sellerOrgLocation?.address}
            contactName={getVendorContacts(
              item.preferredVendor?.contacts.filter(
                (c) => c.receivesOrderNotifications,
              ),
            )}
            orgName={item.sellerOrgLocation?.org.name}
            index={index}
          />
        ),
        sortKey: QueryReleasesSortBy.VendorName,
      },
      {
        wrapper: ReleaseWrapper,
        header: <FormattedMessage id="RELEASE" />,
        item: ({ item }) => <ReleaseName release={item} />,
        sortKey: QueryReleasesSortBy.SequenceNumber,
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <TypeIndicator orderType={item?.type} />,
      },
      {
        wrapper: PoHashWrapper,
        header: <FormattedMessage id="PO_HASH" />,
        item: ({ item }) => <PoNumber>{item.poNumber}</PoNumber>,
        hidden: !includePoNumbering,
        sortKey: QueryReleasesSortBy.PoNumber,
      },
      {
        wrapper: ProjectWrapper,
        header: <FormattedMessage id="PROJECT" />,
        item: ({ item }) => <ProjectNameListDetails project={item.project} />,
        sortKey: QueryReleasesSortBy.ProjectCode,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="CREATE_DATE" />,
        position: "center",
        item: ({ item }) => <ReleaseCreateDate release={item} />,
        sortKey: QueryReleasesSortBy.Recency,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="DELIVERY" />,
        position: "center",
        item: ({ item }) => (
          <ReleaseTimeAndType
            release={item}
            isDelivery={item.includeServices.includes(ServiceType.Delivery)}
          />
        ),
        sortKey: QueryReleasesSortBy.DeliveryDate,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="ORDER_VALUE" />,
        position: "center",
        item: ({ item }) => <ReleaseAmount release={item} />,
        hidden: !hasPermissions,
      },
      {
        wrapper: MdFixedContainer,
        header: "",
        position: "center",
        item: ({ item }) => <DeliveryActionButton delivery={item} />,
      },
      {
        wrapper: WarningsContainer,
        position: "center",
        item: ({ item }) => <ReleaseMissingCostCode release={item} />,
        hidden: !!connectedSourceSystem,
      },
      {
        wrapper: XsFixedContainer,
        header: <FormattedMessage id="DELIVERY_ISSUES" />,
        position: "center",
        item: ({ item }) => <DeliveryIssues count={item.issues.length} />,
      },
      {
        wrapper: StatusContainer,
        header: <FormattedMessage id="STATUS" />,
        position: "center",
        item: ({ item }) => <ReleaseStatusChipWithApprovals release={item} />,
        sortKey: QueryReleasesSortBy.Status,
      },
      {
        wrapper: ActionsContainer,
        header: "",
        position: "start",
        item: ({ item }) => <ReleaseActions id={item.id} release={item} />,
      },
    ],
    [connectedSourceSystem, hasPermissions, includePoNumbering],
  );

  return configuration;
};
