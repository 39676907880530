import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { NoFunction } from "@/types/NoFunction";

import { Fragment, ReactNode, useMemo } from "react";
import { CountType } from "../../circle-counter/CountType";
import { GridSortDirection } from "../GridTable";
import { GridCol } from "../types/GridCol";
import { RenderType } from "../types/RenderType";
import {
  Header,
  PingEffect,
  SearchContainer,
  SearchStyled,
} from "./GridItem.styles";
import { GridSortContainer } from "./components/GridSortContainer";

export type GroupFunctionType<T extends Identity> = (
  category: CategoryState<T>,
  readonly?: boolean,
) => ReactNode;

type Props<T extends Identity, TAdditional> = {
  columns: Array<GridCol<T, TAdditional>>;
  item?: T;
  index?: number;
  readonly?: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  error?: boolean;
  hidden?: boolean;
  renderType?: RenderType;
  category?: CategoryState<T>;
  className?: string;
  count?: CountType;
  visible?: boolean;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  activeSearchColumn?: GridCol<T, TAdditional>;
  setActiveSearchColumn?: (column: GridCol<T, TAdditional>) => void;
  setActiveSortColumn?: (column: GridCol<T, TAdditional>) => void;
  rowError?: boolean;
  sortDirection?: GridSortDirection | null;
  sortedColumn?: GridCol<T, TAdditional>;
  disableSorting?: boolean;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
};

export const GridItem = <
  T extends Identity,
  TCategory extends CategoryState<T>,
  TAdditional,
>({
  item = {} as T,
  index,
  readonly = false,
  error = false,
  hidden = false,
  readonlyFn,
  columns,
  className,
  category = {} as TCategory,
  renderType = RenderType.Item,
  count = "",
  visible,
  editMode = false,
  setEditMode = NoFunction,
  setActiveSearchColumn,
  setActiveSortColumn,
  rowError = false,
  sortDirection,
  sortedColumn,
  disableSorting,
}: Props<T, TAdditional>) => {
  const element = useMemo(() => {
    return columns
      .filter((c) => !c.hidden)
      .map((column, key) => {
        const Wrapper = column.wrapper;
        if (column.break) {
          return column.item?.({
            item,
            index,
            readonly,
            error,
            rowError,
            readonlyFn,
            count,
            editMode,
            setEditMode,
          });
        }

        if (Wrapper) {
          const align = !column.skipAlignment
            ? "justify-" + (column.position || "start")
            : "";
          const commonProps = {
            key,
            className: `group/item ${align} ${className}`,
            $hidden: hidden,
          };
          switch (renderType) {
            case RenderType.Header: {
              const props = { ...commonProps, $header: true };
              const header = column.header
                ? column.header
                : column.headerFn
                  ? column.headerFn(category, readonly)
                  : undefined;
              return (
                <Wrapper {...props} key={props.key}>
                  <Header
                    onClick={() =>
                      (!!column.sortItemFn || column.sortKey) &&
                      !editMode &&
                      !disableSorting
                        ? setActiveSortColumn?.(column)
                        : undefined
                    }
                    $sortable={!!column.sortItemFn || !!column.sortKey}
                  >
                    {header}
                    {(!!column.sortItemFn || column.sortKey) && (
                      <GridSortContainer
                        column={column}
                        sortedColumn={sortedColumn}
                        sortDirection={sortDirection}
                        sortBy={header}
                        disableSorting={disableSorting}
                      />
                    )}
                  </Header>
                  {!!column.searchItemFn && (
                    <SearchContainer>
                      <SearchStyled
                        onClick={() => setActiveSearchColumn?.(column)}
                      />
                    </SearchContainer>
                  )}
                </Wrapper>
              );
            }
            case RenderType.Group: {
              const props = { ...commonProps, $group: true };
              const Category = column.group
                ? column.group(category, readonly)
                : undefined;
              if (!Category && column.hideGroupIfEmpty) {
                return null;
              }
              return (
                <Wrapper {...props} key={props.key}>
                  {Category}
                </Wrapper>
              );
            }
            case RenderType.Details: {
              const props = { ...commonProps, $details: true };
              const Details = column.details
                ? column.details({
                    item,
                    index,
                    readonly,
                    error,
                    rowError,
                    readonlyFn,
                    count,
                    editMode,
                    setEditMode,
                  })
                : null;
              return Details ? (
                <Wrapper {...props} key={props.key}>
                  {Details}
                </Wrapper>
              ) : null;
            }
            case RenderType.Subdetails: {
              const props = { ...commonProps, $subdetails: true };
              const Subdetails = column.subdetails
                ? column.subdetails({
                    item,
                    index,
                    readonly,
                    error,
                    rowError,
                    readonlyFn,
                    count,
                    editMode,
                    setEditMode,
                  })
                : null;
              return Subdetails ? (
                <Wrapper {...props} key={props.key}>
                  {Subdetails}
                </Wrapper>
              ) : null;
            }
            case RenderType.Subgroup: {
              const props = { ...commonProps, $subgroup: true };
              const Category = column.subgroup
                ? column.subgroup(category, readonly)
                : null;
              if (!Category && column.hideSubGroupIfEmpty) {
                return null;
              }
              return (
                <Wrapper {...props} key={props.key}>
                  {Category}
                </Wrapper>
              );
            }
            case RenderType.Footer: {
              return (
                <Wrapper {...commonProps} key={commonProps.key}>
                  {column.footer ?? null}
                </Wrapper>
              );
            }
            default: {
              const props = { ...commonProps, $item: true };
              const Column = visible ? (
                column.item?.({
                  item,
                  index,
                  readonly,
                  error,
                  rowError,
                  readonlyFn,
                  count,
                  editMode,
                  setEditMode,
                })
              ) : (
                <PingEffect />
              );
              return (
                <Wrapper {...props} key={props.key}>
                  {Column}
                </Wrapper>
              );
            }
          }
        }

        return <span key={key} />;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category.id,
    category.items?.length,
    className,
    columns,
    count,
    editMode,
    error,
    rowError,
    hidden,
    index,
    item,
    readonly,
    readonlyFn,
    renderType,
    visible,
  ]);
  return <Fragment key={count}>{element}</Fragment>;
};
