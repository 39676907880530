import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { RELEASE } from "@/common/queries/release";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AddToReleaseItemInput,
  InvoiceStatus,
  OrgMaterialFieldsFragment,
  UomsDocument,
  UpdateContractorReleaseItemInput,
  UpdateReceiptMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useReceiptSequence } from "../../receipts/providers/ReceiptsSequenceProvider";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useReceipt } from "./ReceiptProvider";

type ProviderContextType = {
  syncUpdateReleaseFromReceipt: (
    values: ReceiptCreateReleaseFormValues,
  ) => Promise<boolean>;
  saving: boolean;
  updateRelease: (values: ReceiptCreateReleaseFormValues) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncUpdateReleaseFromReceipt: NoFunctionBooleanPromise,
  saving: false,
  updateRelease: NoFunctionBooleanPromise,
});

export const ReceiptUpdateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { manufacturers } = useManufacturers();
  const { sequenceActive, navigateToNextSequence } = useReceiptSequence();
  const { spreadsheetData, getRemovedRowIds, gotoInvalidRow } =
    useColumnMapper();
  const { costCodes } = useCostCodes();

  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingManufacturers,
    addMissingZones,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { materials, updateMaterials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { receipt, updateReceipt, isItemized, approveReceipt } = useReceipt();
  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  const getNonItemizedTotalItem = async (
    values: ReceiptCreateReleaseFormValues,
  ): Promise<AddToReleaseItemInput> => {
    const costCode = costCodes.find(
      (c) => c.id === values.costCodeId,
    )?.description;

    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );

    let newMaterials;
    if (!lumpSumMaterial) {
      newMaterials = await updateMaterials({
        addedMaterials: [
          {
            newOrgCatalogSKU: {
              defaultUom: LUMP_SUM_UOM,
              name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
            },
          },
        ],
      });
    }

    return {
      projectItem: {
        estimateUom: LUMP_SUM_UOM,
        orgCatalogSkuId: (
          lumpSumMaterial || (newMaterials as OrgMaterialFieldsFragment[])[0]
        )?.material.id,
      },
      quantityDecimal: String(values.subtotal),
      position: 0,
      unitPrice: "1",
      tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
      costCodeId: values.costCodeId,
      name: costCode
        ? intl.$t({ id: "RECEIPT_COST_CODE_EXPENSES" }, { costCode })
        : intl.$t({ id: "RECEIPT_EXPENSES" }),
    };
  };

  const syncUpdateReleaseFromReceipt = async (
    values: ReceiptCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newZones = (await addMissingZones(release?.project?.id || "")) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          ...(!existingItem?.buyoutItem && {
            manufacturerId: manufacturer?.id,
          }),
          uom,
          quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          clearManufacturer: !manufacturer,
          costCodeId: getCostCodeId(row),
          clearCostCode: !getCostCodeId(row),
          zoneId: zone?.id,
        });
      } else {
        newItems.push({
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          manufacturerId: manufacturer?.id,
          quantityDecimal: quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          costCodeId: getCostCodeId(row),
          zoneId: zone?.id,
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (release) {
      const initiallyItemized = isItemized(receipt);

      if (!initiallyItemized) {
        itemsToRemove.push(release.items?.[0]?.id);
      }

      try {
        const { errors } = await updateReleaseMutation({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              assignDefaultCostCodes: false,
              prefillPrices: false,
              typeId: values.orderTypeId,
              requestedTime: values.issueDate?.getTime(),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          if (receipt?.status === InvoiceStatus.AwaitingApproval) {
            await approveReceipt({ invoiceId: receipt?.id });
          }
          if (sequenceActive) {
            navigateToNextSequence();
          }
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const updateRelease = async (values: ReceiptCreateReleaseFormValues) => {
    try {
      setSaving(true);

      const initiallyItemized = isItemized(receipt);

      const nonItemizedTotalItem = await getNonItemizedTotalItem(values);

      const costCode = costCodes.find((c) => c.id === values.costCodeId)?.id;

      if (!initiallyItemized) {
        await updateMaterials({
          updates: [
            {
              orgMaterialId: release?.items[0].projectItem?.material.id || "",
              costCodeId: costCode,
            },
          ],
        });
      }

      const updates: UpdateContractorReleaseItemInput[] = [
        {
          quantityDecimal: values.subtotal.toString(),
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          releaseItemId: release?.items[0].id || "",
          costCodeId: values.costCodeId,
        },
      ];

      const { errors } = await updateReleaseMutation({
        variables: {
          input: initiallyItemized
            ? {
                releaseId: release?.id || "",
                requestedTime: values.issueDate?.getTime(),
                updates: undefined,
                removedItems: release?.items.map((item) => item.id),
                addedItems: [nonItemizedTotalItem],
                taxRate: values.taxRate || undefined,
                customTaxAmount: values.customTaxAmount || undefined,
                version: release?.version,
                typeId: values.orderTypeId,
              }
            : {
                releaseId: release?.id || "",
                requestedTime: values.issueDate?.getTime(),
                updates,
                taxRate: values.taxRate || undefined,
                customTaxAmount: values.customTaxAmount || undefined,
                version: release?.version,
                typeId: values.orderTypeId,
              },
        },
        awaitRefetchQueries: true,
      });
      setError(errors);
      let receiptUpdateResult: UpdateReceiptMutation | null | undefined = null;
      if (!errors && receipt?.id) {
        receiptUpdateResult = await updateReceipt({
          id: receipt?.id,
          issueDate: values.issueDate?.getTime(),
        });
      }
      if (!errors && receiptUpdateResult?.updateReceipt) {
        if (receipt?.status === InvoiceStatus.AwaitingApproval) {
          await approveReceipt({ invoiceId: receipt?.id });
        }
        if (sequenceActive) {
          navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
        }
      }

      setSaving(false);
      return Boolean(!errors && receiptUpdateResult?.updateReceipt);
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncUpdateReleaseFromReceipt,
        saving: saving || updating,
        updateRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptUpdateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
