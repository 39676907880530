import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateProjectInput,
  ProjectProjectsFieldsFragment,
  ProjectStatus,
  QueryProjectsFilter,
  namedOperations,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useProjectsWithPagination } from "../hooks/useProjectsWithPagination";

type ProjectsProviderContextType = {
  projects: ProjectProjectsFieldsFragment[];
  createProject: (
    projectInput: CreateProjectInput,
  ) => Promise<{ id: string; name: string } | undefined>;
  deleteProject: (id: string) => Promise<void>;
  getActiveProjects: (locationId: string) => void;
  loading: boolean;
  totalCount: number;
  filter?: QueryProjectsFilter;
  setFilter: (filter: QueryProjectsFilter) => void;
  isFiltered: boolean;
};

const ProjectsProviderContext = createContext<ProjectsProviderContextType>({
  projects: [],
  createProject: NoFunctionUndefinedPromise,
  deleteProject: NoFunctionUndefinedPromise,
  getActiveProjects: NoFunction,
  loading: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const ProjectsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { readValue, setValue } = useLocalStorage();
  const [filter, setFilter] = useState<QueryProjectsFilter>({
    statuses: [ProjectStatus.Active, ProjectStatus.Awarded],
    ...getFiltersQueryParam(),
  });
  const {
    projects,
    loading: queryLoader,
    error,
    totalCount,
  } = useProjectsWithPagination(filter);
  const [createNewProject, { loading: createProjectLoader }] =
    useCreateProjectMutation();
  const [deleteExistingProject] = useDeleteProjectMutation();
  const { setError } = useGlobalError();

  const loading = useMemo(
    () => queryLoader || createProjectLoader,
    [queryLoader, createProjectLoader],
  );

  useErrorEffect(error);

  useEffect(() => {
    const localStorageSettings = readValue<QueryProjectsFilter>(
      LOCAL_STORAGE_KEYS.PROJECTS_LIST_FILTER,
    ) as QueryProjectsFilter;

    setFilter({
      statuses:
        localStorageSettings?.statuses !== undefined
          ? localStorageSettings?.statuses
          : filter?.statuses,
      deleted: localStorageSettings?.deleted,
      ...getFiltersQueryParam(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFiltersQueryParam, readValue]);

  const getActiveProjects = (orgLocationID: string) => {
    if (orgLocationID !== filter?.orgLocationID) {
      setFilter({
        orgLocationID,
        statuses: [ProjectStatus.Awarded, ProjectStatus.Active],
      });
    }
  };

  const setFilterUpdate = (filter: QueryProjectsFilter) => {
    setFiltersQueryParams(filter);
    setValue(LOCAL_STORAGE_KEYS.PROJECTS_LIST_FILTER, filter);
    setFilter(filter);
  };

  const createProject = async (projectInput: CreateProjectInput) => {
    const {
      locationId,
      name,
      address,
      jobNumber,
      status,
      startDate,
      budget,
      team,
      tags,
      zones,
      taxExempt,
    } = projectInput;
    if (locationId && name) {
      try {
        const newAddress = {
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          country: address.country,
          postalCode: address.postalCode,
          state: address.state,
        };
        const { data: newProject, errors } = await createNewProject({
          variables: {
            input: {
              name,
              locationId,
              address: newAddress,
              jobNumber,
              startDate,
              status,
              budget,
              team: team.filter((user) => !!user), //remove potential erroneous "" due to race condition
              tags,
              zones,
              taxExempt,
            },
          },
          update: (cache) => {
            cleanQuery(cache, namedOperations.Query.Projects);
          },
        });
        setError(errors);
        if (newProject?.createNewProject) {
          return newProject?.createNewProject;
        }
      } catch (errors) {
        setError(errors);
      }
    }
  };

  const deleteProject = async (id: string) => {
    try {
      const { errors } = await deleteExistingProject({
        variables: { id },
        update: (cache) => {
          cleanQuery(cache, namedOperations.Query.Projects);
        },
      });
      setError(errors);
    } catch (errors) {
      setError(errors);
    }
  };

  return (
    <ProjectsProviderContext.Provider
      value={{
        filter,
        isFiltered: !!(filter?.name || filter?.statuses?.length),
        setFilter: setFilterUpdate,
        projects: projects || [],
        createProject,
        getActiveProjects,
        deleteProject,
        loading,
        totalCount,
      }}
    >
      {children}
    </ProjectsProviderContext.Provider>
  );
};

export const useProjects = (): ProjectsProviderContextType =>
  useContext(ProjectsProviderContext);
