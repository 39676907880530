import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import {
  DistributorReleaseFieldsFragment,
  UpdateChargeInput,
} from "@/generated/graphql";
import { FC } from "react";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";

type Props = {
  release?: DistributorReleaseFieldsFragment | null;
  setBulkAdditionalCharges?: (charges: UpdateChargeInput[]) => void;
  setBulkTaxRate?: (taxRate: string | undefined) => void;
  showError?: boolean;
  readonly?: boolean;
};

export const DistributorReleaseAdditionalChargesAndTaxes: FC<Props> = ({
  release,
  ...props
}) => {
  const { updateVendorRelease } = useDistributorRelease();

  if (!release) {
    return null;
  }

  return (
    <ReleaseAdditionalChargesAndTaxes
      release={release}
      total={release.total}
      updateRelease={updateVendorRelease}
      includePaymentTerms
      editablePaymentTerms
      includeNotesPanel={false}
      taxExempt={{
        isProjectTaxExempt: release?.project?.taxExempt,
        isVendorTaxExempt: release?.preferredVendor?.taxExempt,
        vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
      }}
      {...props}
    />
  );
};
