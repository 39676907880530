import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { PAGINATION, QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useCallback } from "react";
import { ObjectType, SortState, useSortingStore } from "../useSortingStore";
import { compressQueryParam } from "../utils/compressQueryParam";

const SORTING = {
  SORT_BY: "sort_by",
  SORT_DESC: "sort_desc",
  SORT_OBJECT_TYPE: "sort_object_type",
};

type SortingOptions = {
  updateQueryString?: boolean;
};

export const useSorting = (
  objectType?: ObjectType,
  { updateQueryString }: SortingOptions = { updateQueryString: true },
) => {
  const { getSortEntity, setSortEntity, sortEntities, removeSortEntity } =
    useSortingStore();
  const { setQueryParams, queryParams } = useQueryParams();
  const { firstPage } = usePagination();

  const sortEntity = getSortEntity(objectType);

  const onSortEntity = useCallback(
    (sortState: SortState) => {
      if (updateQueryString) {
        const size = queryParams.get(PAGINATION.FIRST);
        const params = {
          [SORTING.SORT_BY]: sortState.field,
          [SORTING.SORT_DESC]: sortState.descending ? "true" : "false",
          [SORTING.SORT_OBJECT_TYPE]: objectType,
        };
        const paginationParams = {
          [PAGINATION.PAGE]: "1",
          [PAGINATION.FIRST]: size,
          [PAGINATION.AFTER]: undefined,
          [PAGINATION.BEFORE]: undefined,
          [PAGINATION.LAST]: undefined,
        };
        const compressedQueryParams = compressQueryParam(params);

        setQueryParams({
          [QUERYSTRING.SORT]: compressedQueryParams,
          ...paginationParams,
        });
        firstPage();
        setSortEntity(sortState);
      }
    },
    [
      firstPage,
      objectType,
      queryParams,
      setQueryParams,
      setSortEntity,
      updateQueryString,
    ],
  );

  const onRemoveSortEntity = useCallback(() => {
    removeSortEntity(objectType);
    setQueryParams({ [QUERYSTRING.SORT]: "" });
  }, [objectType, removeSortEntity, setQueryParams]);

  return {
    sortEntities,
    getSortEntity,
    sortEntity,
    setSortEntity: onSortEntity,
    removeSortEntity: onRemoveSortEntity,
  };
};
