import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { getEst8amDate } from "@/common/utils/dates/getEst8amDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  AddToReleaseItemInput,
  InvoiceItemFieldsFragment,
  OrgMaterialFieldsFragment,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "../components/matched-order/components/invoice-create-release/InvoiceCreateRelease.config";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useLumpSumInvoiceReleaseItems } from "../hooks/useLumpSumInvoiceReleaseItems";
import { useInvoiceImportExternalPO } from "./InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type CreateReleaseOptions = {
  isChangeOrder?: boolean;
};

type ProviderContextType = {
  invoiceItems: InvoiceItemFieldsFragment[];
  setInvoiceItems: (items: InvoiceItemFieldsFragment[]) => void;
  syncCreateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean | undefined>;
  loading: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  createRelease: (values: InvoiceCreateReleaseFormValues) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  invoiceItems: [],
  setInvoiceItems: NoFunction,
  syncCreateReleaseFromInvoice: NoFunctionBooleanPromise,
  loading: false,
  spreadsheetViewColumns: [],
  createRelease: NoFunctionBooleanPromise,
});

export const InvoiceCreateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { connectedSourceSystem, settings } = useOrgSettings();
  const { materials } = useMaterials();
  const { vendors } = useVendors();
  const { invoice, refetch, setFooterState } = useInvoiceVerification();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    importedPoExternalId,
    importedPoItemized,
    importedItems,
    importedDescription,
  } = useInvoiceImportExternalPO();
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingZones,
    getCellWithAdditionalData,
    findMaterialByName,
    getPrefilledValue,
  } = useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();
  const { updateMaterials } = useMaterials();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSystemAlert } = useSnackbar();
  const { getLumpSumReleaseItem } = useLumpSumInvoiceReleaseItems();
  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );
  const [saving, setSaving] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemFieldsFragment[]>(
    (invoice?.items ?? []).map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
        extPrice: calcExtPrice(item?.quantityDecimal, item?.unitPrice),
      };
    }),
  );

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();

  const getOrCreateLumpSumMaterial = useCallback(async () => {
    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );

    let newMaterials = lumpSumMaterial ? [lumpSumMaterial] : [];
    if (!lumpSumMaterial) {
      newMaterials = (await updateMaterials({
        addedMaterials: [
          {
            newOrgCatalogSKU: {
              defaultUom: LUMP_SUM_UOM,
              name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
            },
          },
        ],
      })) as OrgMaterialFieldsFragment[];
    }
    return newMaterials[0];
  }, [materials, updateMaterials]);

  const getNewItems = async (values: InvoiceCreateReleaseFormValues) => {
    const newItems: AddToReleaseItemInput[] = [];

    setSaving(true);
    const newMaterials = (await addMissingMaterials([], LUMP_SUM_UOM)) || [];
    const newZones = (await addMissingZones(values.projectId)) || [];
    setSaving(false);
    await getOrCreateLumpSumMaterial();

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity) || "1";
      const unitPrice = Number(
        getCellValue(
          row,
          invoice?.isDeliverySlip
            ? COLUMN_TYPE.PrefilledPrice
            : COLUMN_TYPE.UnitPrice,
        ),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes);
      const vendor = vendors?.find((v) => v.id === values.vendorId);

      const prefilledPrice = getPrefilledValue(
        rowMaterialText,
        vendor ? vendorLabelFormatter(vendor.sellerOrgLocation) : "",
        "",
        uom,
      );

      const importedItem = importedItems?.find((_, i) => i === index);
      newItems.push({
        invoiceId: invoice?.id ?? "",
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        ...(isLumpSum && { name: rowMaterialText }),
        instructions: { text: notes },
        quantityDecimal,
        unitPrice: String(unitPrice),
        pricePrenegotiated: String(unitPrice) === prefilledPrice.value,
        position: index,
        zoneId: zone?.id,
        costCodeId: getCostCodeId(row),
        poItemExternalId: importedItem?.id,
      });
    });

    return newItems;
  };

  const getLumpSumItems = async () => {
    const newItems: AddToReleaseItemInput[] = [];
    const material = await getOrCreateLumpSumMaterial();

    spreadsheetData.forEach((row) => {
      const costCodeText = getCellWithAdditionalData(row, COLUMN_TYPE.CostCode);

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const zone = zones.find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const unitPrice = "1";

      newItems.push({
        invoiceId: invoice?.id ?? "",
        projectItem: {
          estimateUom: LUMP_SUM_UOM,
          orgCatalogSkuId: material.material.id,
        },
        costCodeId: getCostCodeId(row),
        name: costCodeText,
        zoneId: zone?.id,
        quantityDecimal: quantityDecimal.toString(),
        unitPrice: String(unitPrice),
      });
    });

    return newItems;
  };

  const createReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
    newItems: AddToReleaseItemInput[],
    options?: CreateReleaseOptions,
  ) => {
    if (newItems.length > 0) {
      try {
        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              invoiceId: invoice?.id ?? "",
              projectId: values.projectId ?? "",
              warehouseId:
                values.fulfillmentLocationId === values.projectId
                  ? undefined
                  : values.fulfillmentLocationId,
              sellerOrgLocationId: values.vendorId,
              poNumber: values.poNumber,
              time: getEst8amDate(values.orderDate)?.getTime(),
              items: newItems,
              paymentTerm: values.paymentTerm
                ? Number(values.paymentTerm)
                : undefined,
              taxRate: values.taxRate,
              customTaxAmount: values.customTaxAmount || undefined,
              additionalCharges: values.additionalCharges?.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              retroactive: true,
              mapping:
                importedPoExternalId &&
                connectedSourceSystem &&
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? {
                      externalId: importedPoExternalId,
                      sourceSystem: connectedSourceSystem,
                    }
                  : undefined,
              typeId: values.orderTypeId || undefined,
              assignPOItemZones:
                !!importedPoExternalId &&
                importedPoItemized === true &&
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? true
                  : undefined,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
              description:
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
                importedDescription
                  ? importedDescription
                  : null,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
          ],
        });
        await refetch();
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        if (data) {
          setSystemAlert(
            intl.$t(
              {
                id:
                  matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                    ? options?.isChangeOrder
                      ? "INVOICE_WAS_SUCCESSFULLY_IMPORTED_CHANGE_ORDER"
                      : "INVOICE_WAS_SUCCESSFULLY_IMPORTED"
                    : "INVOICE_ORDER_WAS_SUCCESSFULLY_CREATED",
              },
              {
                integration: connectedSourceSystem
                  ? intl.$t({
                      id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                    })
                  : "",
                poNumber: invoice?.poNumber,
                orderNumber: data.createStandaloneRelease?.sequenceNumber,
              },
            ),
            {
              type:
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? SystemAlertType.AUTOMATIC
                  : SystemAlertType.MANUAL,
              clear: true,
            },
          );
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const syncCreateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        ...(importedPoItemized
          ? [
              COLUMN_TYPE.Material,
              COLUMN_TYPE.UOM,
              COLUMN_TYPE.Quantity,
              invoice?.isDeliverySlip
                ? COLUMN_TYPE.PrefilledPrice
                : COLUMN_TYPE.UnitPrice,
            ]
          : [COLUMN_TYPE.CostCode]),
        COLUMN_TYPE.UnitPrice,
      ]) ||
      !validateRowValues(
        [
          ...(importedPoItemized
            ? [
                COLUMN_TYPE.Quantity,
                invoice?.isDeliverySlip
                  ? COLUMN_TYPE.PrefilledPrice
                  : COLUMN_TYPE.UnitPrice,
                COLUMN_TYPE.UOM,
                COLUMN_TYPE.CostCode,
              ]
            : [COLUMN_TYPE.UnitPrice]),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems = importedPoItemized
      ? await getNewItems(values)
      : await getLumpSumItems();
    if (
      matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
      newItems.find((i) => !i.poItemExternalId)
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: intl.$t(
          { id: "RECORD_ORDER_CHANGE_ORDER" },
          {
            integration: intl.$t({
              id: `INTEGRATION_${connectedSourceSystem}`,
            }),
          },
        ),
        handleConfirm: async () => {
          if (
            await createReleaseFromInvoice(values, newItems, {
              isChangeOrder: !!settings?.integrations.sourceSystems.find(
                (s) => s.connected,
              )?.autoPostPOs,
            })
          ) {
            if (
              invoice &&
              !INVOICE_READONLY_STATUSES.includes(invoice.status)
            ) {
              setMatchedOrderViewState(
                MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
              );
            } else {
              setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
            }
            setFooterState(InvoiceFooterState.DEFAULT);
          }
        },
      });

      return;
    }
    return await createReleaseFromInvoice(values, newItems);
  };

  const createRelease = async (values: InvoiceCreateReleaseFormValues) => {
    try {
      setSaving(true);
      const items = await getLumpSumReleaseItem(values);
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: invoice?.id ?? "",
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            time: values.orderDate?.getTime() ?? undefined,
            items,
            taxRate: values.taxRate || undefined,
            customTaxAmount: values.customTaxAmount || undefined,
            retroactive: true,
            typeId: values.orderTypeId || undefined,
            poNumber: values.poNumber,
            taxCodeId: values.taxCodeId,
            taxType: values.taxType,
            additionalCharges: values.additionalCharges,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      if (data) {
        setSystemAlert(
          intl.$t(
            {
              id: "INVOICE_ORDER_WAS_SUCCESSFULLY_CREATED",
            },
            {
              orderNumber: data.createStandaloneRelease?.sequenceNumber,
            },
          ),
          { type: SystemAlertType.MANUAL, clear: true },
        );
      }
      await refetch();
      setError(errors);
      setSaving(false);
      return !errors;
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        invoiceItems,
        setInvoiceItems,
        syncCreateReleaseFromInvoice,
        createRelease,
        loading: saving || creatingRelease,
        spreadsheetViewColumns: useInvoiceCreateReleaseSpreadsheetConfig(),
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceCreateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
